import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'full-screen-loader',
  templateUrl: './full-screen-loader.component.html',
  styleUrls: ['./full-screen-loader.component.css']
})
export class FullScreenLoaderComponent implements OnInit {

  @Input() text = '';

  constructor() { }

  ngOnInit() {
  }

}
