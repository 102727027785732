import { takeUntil, Subject } from 'rxjs';
import { ScrollOutletService } from './../../../services/scroll-outlet.service';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[scrollOutletToBottom]'
})
export class ScrollOutletToBottomDirective {

  private _destroyed$ = new Subject<boolean>();

  @Input() scrollOutletToBottom?: string;

  constructor(
    private _scrollOutletService: ScrollOutletService,
    private _el: ElementRef
  ) {
    this._scrollOutletService.scrollToBottom$.pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._el.nativeElement.scrollTop = this._el.nativeElement.scrollHeight;
      });
  }

}
