<inl-modal-sceleton (close)="close()">
  <div header>
    {{'main.edit-address'|translate}}
  </div>
  <div body>
    <inl-form-address [formControl]="addressControl"></inl-form-address>
  </div>
  <div footer>
    <inl-button [disabled]="addressControl.pristine" (inlClick)="applyAddress()">{{'main.ok'|translate}}</inl-button>
  </div>
</inl-modal-sceleton>
