import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private _beforeRediecrURL = '';

  constructor(
    private _loginSrv: LoginService,
    private _router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._loginSrv.token$.value) {
      return true;
    }
    // проверить корректнось урла
    this._loginSrv.logout(state.url);
    return false;
  }

}
