import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../inl-form-address/models/address';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-select-address-modal',
  templateUrl: './select-address-modal.component.html',
  styleUrls: ['./select-address-modal.component.css']
})
export class SelectAddressModalComponent implements OnInit {

  @Input() address: Address = new Address();

  hasAddress = false;
  addressControl = new FormControl<Address | null>(new Address());

  private _destroyed$ = new Subject<boolean>();

  constructor(
    private _modalService: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.addressControl.patchValue(this.address, { emitEvent: false });
    this.addressControl.valueChanges.pipe(takeUntil(this._destroyed$)).subscribe(address => {
      console.log({address});
      this.hasAddress = true;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  close() {
    this.hasAddress = false;
    this._modalService.close();
  }

  applyAddress(){
    this._modalService.close();
  }

}
