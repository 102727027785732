import { Directive, HostListener, Input, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[inlScrollHandle]'
})
export class ScrollBottomDirective {
  @Input() inlScrollHandle: string | null | undefined = null;
  @Output() inlSrollHandleOnBottom = new EventEmitter<boolean>();
  @Output() inlSrollHandleOnTop = new EventEmitter<boolean>();
  @Output() inlScrollHandlerToBottomLength = new EventEmitter<number>();

  constructor(private elRef: ElementRef) {
  }

  @HostListener("scroll", ["$event"])
  onWindowScroll() {
    const nativeElement = this.elRef.nativeElement;

    // console.log('scrollTop', this.elRef.nativeElement.scrollTop)
    // console.log('offsetHeight', this.elRef.nativeElement.offsetHeight)
    // console.log('scrollHeight', this.elRef.nativeElement.scrollHeight)
    // console.log('crollToBottom', nativeElement.scrollHeight - nativeElement.offsetHeight - nativeElement.scrollTop);
    this.inlScrollHandlerToBottomLength.emit(nativeElement.scrollHeight - nativeElement.scrollTop);

    let pos = (nativeElement.scrollTop || nativeElement.scrollTop) + nativeElement.offsetHeight;
    let max = nativeElement.scrollHeight;
    if (pos == max) {
      this.inlSrollHandleOnBottom.next(true);
    }
    if (this.elRef.nativeElement.scrollTop === 0) {
      this.inlSrollHandleOnTop.emit(true);
    };
  }

}
