import { Component, OnInit, Input } from '@angular/core';
import { BsTextColors } from 'src/app/types/bs-text-colors';

/**
 * @link https://icons.getbootstrap.com/
 */
@Component({
  selector: 'inl-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css']
})
export class IconComponent implements OnInit {

  @Input() name = '';
  @Input() size: 'md' | 'lg' | 's' | 'xs' = 'md';
  @Input() color: BsTextColors = 'text-primary';
  @Input() loadingColor: BsTextColors = 'text-primary';

  constructor() { }

  ngOnInit() {
  }

}
