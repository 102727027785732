import { Subject, takeUntil } from 'rxjs';
import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'inl-form-checkbox',
  templateUrl: './inl-form-checkbox.component.html',
  styleUrls: ['./inl-form-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlFormCheckboxComponent),
      multi: true
    }
  ]
})
export class InlFormCheckboxComponent implements OnInit, ControlValueAccessor, OnDestroy {

  checkBoxFormControl = new FormControl();

  private _onChange: any;
  private _onDestroy$ = new Subject<boolean>();

  // todo пока реализованно только для arrayValue === true, доделать
  @Input() title: string = '';
  @Input() value: any;

  id: string = performance.now() + '';

  constructor() { }
  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
  writeValue(value: any): void {
    this.checkBoxFormControl.setValue(value === this.value);
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  ngOnInit() {
    this.checkBoxFormControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(v => {
      if (!this._onChange) return;
      this._onChange(v);
    });
  }

}
