import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-messaged-confirm',
  templateUrl: './messaged-confirm.component.html',
  styleUrls: ['./messaged-confirm.component.css']
})
export class MessagedConfirmComponent implements OnInit {

  @Input() header: string = '';
  @Input() placeholder: string = 'Введите сообщение';
  @Input() okButtonText: string = 'Ok';
  @Input() cancellButtonText: string = 'Отмена';
  @Input() preText: string = '';

  @Output() close = new EventEmitter();
  @Output() confirm = new EventEmitter<string | null>();
  @Output() cancell = new EventEmitter();

  textControl = new FormControl<string>('', Validators.required);

  constructor() { }

  ngOnInit() {
    if (this.preText) this.textControl.patchValue(this.preText);
  }

}
