import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  @Input() header: string = '';
  @Input() confirmText: string = '';
  @Input() okButtonText: string = 'Ok';
  @Input() cancellButtonText: string = 'Отмена';

  @Output() closePressed = new EventEmitter();
  @Output() okPressed = new EventEmitter();
  @Output() cancellPressed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
