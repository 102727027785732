import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  private _isRefreshed$ = new Subject<boolean>();
  private _isRefreshing = false;

  constructor(
    private _loginSrv: LoginService,
    private _router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(e => {
      if (e.status === 401) {
        return this._handle401Error$(req, next);
      } else if (e.status === 400 && this._isRefreshing) {
        this._loginSrv.logout(this._router.url);
        return throwError(() => e);
      }
      return throwError(() => e);
    }));

  }

  private _handle401Error$(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('401 ошибка!!!!');
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      return this._loginSrv.refresh$()
        .pipe(
          tap(() => {
            this._isRefreshing = false;
            this._isRefreshed$.next(true);
          }),
          switchMap(() => next.handle(req)),
        )
    } else {
      return this._isRefreshed$.pipe(first(), switchMap(() => next.handle(req)));
    }
  }

}
