<form class="input-group flex-nowrap w-100">
  <span *ngIf="showIcon" class="input-group-text" id="123"><inl-icon name="house-door"></inl-icon></span>
  <input [formControl]="addressControll" (focus)="onFocus()" (focusout)="onFocusOut()" type="text"
    class="form-control w-100" [placeholder]="'main.input-address'|translate" aria-describedby="123">
  <ul class="list-group suggests" *ngIf="(suggests$|async)?.length">
    <li (click)="selectSuggest(suggest)" *ngFor="let suggest of suggests$|async"
      class="list-group-item list-group-item-action cursor-pointer">
      {{suggest.value}}</li>
  </ul>
</form>
