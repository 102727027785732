import { RefreshTokenService } from './interceptors/refresh-token.service';
import { ToastsComponent } from './components/toasts/toasts.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthComponent } from './components/auth/auth.component';
import { CoreModule } from './modules/core/core.module';
import { PipesModule } from './pipes/pipes.module';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorsInterceptorsService } from './interceptors/errors-interceptors.service';
import { ApiInterceptorService } from './interceptors/api-interceptor.service';

/**
 * Документация к ngx-translate
 * @link https://www.codeandweb.com/ngx-translate/documentation
 */

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ToastsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    PipesModule,
    CoreModule,
    NgbToastModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru-RU',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptorsService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}
