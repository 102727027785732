<div class="container-fluid w-100 h-100 d-flex align-items-center justify-content-center">
  <inl-card>

    <ng-container title>
      {{'authorization.title'|translate}}
    </ng-container>

    <ng-container body>
      <form [formGroup]="mainFG">

        <inl-form-input label="Логин" [formControl]="mainFG.controls.username" placeholder="Введите логин">
        </inl-form-input>

        <inl-form-input label="Пароль" [formControl]="mainFG.controls.password" type="password"
          placeholder="Введите пароль">
        </inl-form-input>

        <inl-button [loading]="loading" (inlClick)="authorize()" [disabled]="mainFG.invalid">
          {{'authorization.buttonText'|translate}}
        </inl-button>

      </form>
    </ng-container>
  </inl-card>

</div>
