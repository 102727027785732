import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollOutletService {

  scrollToBottom$ = new Subject<boolean>();

  constructor() { }

  setFromBottomLength(length: number) {
    const outletEl = document.getElementById('private-outlet');
    outletEl?.scrollTo(0, outletEl.scrollHeight - length);
  }

}
