import { EBsButtonColor } from './../../../../enums/e-bs-button-color.enum';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'inl-button',
  templateUrl: './inlisto-button.component.html',
  styleUrls: ['./inlisto-button.component.css']
})
export class InlistoButtonComponent implements OnInit {

  @Input() disabled = false;
  @Input() loading = false;
  @Input() colorClass: EBsButtonColor | string = EBsButtonColor['btn-primary'];
  @Input() icon: string = '';

  @Output() inlClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
