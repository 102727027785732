import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'inlisto-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  mainFG = new FormGroup({
    username: new FormControl<string>(environment.autoFillAuthData ? '+7(999)999-99-99' : '', Validators.required),
    password: new FormControl<string>(environment.autoFillAuthData ? '123qwe!@#' : '', Validators.required)
  });

  loading = false;

  constructor(
    private _router: Router,
    private _loginSrvc: LoginService
  ) { }

  ngOnInit() {
  }

  authorize() {
    this.loading = true;
    this._loginSrvc.login$(this.mainFG.value.username as string, this.mainFG.value.password as string)
      .subscribe(() => {
        this._router.navigate([this._loginSrvc.redirectUrl ? this._loginSrvc.redirectUrl : '../private']);
      })
      .add(() => this.loading = false);
  }

}

