import { FullScreenLoaderComponent } from './components/full-screen-loader/full-screen-loader.component';
import { IconsModule } from './../icons-module/icons-module.module';
import { CdkModule } from './../cdk/cdk.module';
import { MessagedConfirmDirective } from './directives/messaged-confirm.directive';
import { MessagedConfirmComponent } from './components/messaged-confirm/messaged-confirm.component';
import { ConfirmDirective } from './directives/confirm.directive';
import { ModalSceletonComponent } from './components/modal-sceleton/modal-sceleton.component';
import { PipesModule } from './../../pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from './components/form-input/form-input.component';
import { InlistoButtonComponent } from './components/inlisto-button/inlisto-button.component';
import { CardComponent } from './components/card/card.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { InlFormCheckboxComponent } from './components/inl-form-checkbox/inl-form-checkbox.component';
import { InlSpinnerComponent } from './components/inl-spinner/inl-spinner.component';
import { ScrollBottomDirective } from './directives/scroll-handler.directive';
import { OpenProfileDirective } from './directives/open-profile.directive';
import { FormInputFrontComponent } from './components/form-input/form-input-front/form-input-front.component';
import { ScrollOutletToBottomDirective } from './directives/scroll-outlet-to-bottom.directive';
import { InlIconButtonComponent } from './components/inl-icon-button/inl-icon-button.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { InlFormAddressComponent } from './components/inl-form-address/inl-form-address.component';
import { SelectAddressDirective } from './directives/select-address.directive';
import { SelectAddressModalComponent } from './components/select-address-modal/select-address-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    CdkModule,
    IconsModule,
    TranslateModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    FormInputComponent,
    InlistoButtonComponent,
    CardComponent,
    ModalSceletonComponent,
    ConfirmDirective,
    ConfirmComponent,
    MessagedConfirmComponent,
    MessagedConfirmDirective,
    FullScreenLoaderComponent,
    InlFormCheckboxComponent,
    InlSpinnerComponent,
    ScrollBottomDirective,
    OpenProfileDirective,
    FormInputFrontComponent,
    ScrollOutletToBottomDirective,
    InlIconButtonComponent,
    PhoneInputComponent,
    InlFormAddressComponent,
    SelectAddressDirective,
    SelectAddressModalComponent
  ],
  exports: [
    FormInputComponent,
    InlistoButtonComponent,
    CardComponent,
    ModalSceletonComponent,
    ConfirmDirective,
    ConfirmComponent,
    MessagedConfirmComponent,
    MessagedConfirmDirective,
    IconsModule,
    FullScreenLoaderComponent,
    InlFormCheckboxComponent,
    InlSpinnerComponent,
    ScrollBottomDirective,
    OpenProfileDirective,
    FormInputFrontComponent,
    ScrollOutletToBottomDirective,
    InlIconButtonComponent,
    PhoneInputComponent,
    InlFormAddressComponent,
    SelectAddressDirective,
    SelectAddressModalComponent
  ]
})
export class CoreModule { }
