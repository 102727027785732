import { ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject, debounceTime, takeUntil, timer } from 'rxjs';
import { Address } from './models/address';
import { YaSuggestGeoObj } from './models/ya-suggest-geo-obj';
import { YaMapService } from './services/ya-map.service';

@Component({
  selector: 'inl-form-address',
  templateUrl: './inl-form-address.component.html',
  styleUrls: ['./inl-form-address.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlFormAddressComponent),
      multi: true
    }
  ]
})
export class InlFormAddressComponent implements OnInit, ControlValueAccessor {

  @Input() showIcon = true;

  addressControll = new FormControl<string>('');
  suggests$ = new BehaviorSubject<YaSuggestGeoObj[]>([]);

  private _destroyed$ = new Subject<boolean>();
  private _onChange: any;
  private _address?: Address;

  constructor(
    private _yaMapService: YaMapService,
    private _cdr: ChangeDetectorRef
  ) { }

  writeValue(address: Address): void {
    this._address = address;
    this.addressControll.patchValue(address.fullAddress || '', { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnInit() {
    this._yaMapService.initMap$().subscribe();
    this.addressControll.valueChanges
      .pipe(
        debounceTime(300)
      )
      .subscribe(v => {
        this._yaMapService.updateSuggests$(v || '')
          .pipe(takeUntil(this._destroyed$))
          .subscribe(suggests => {
            this.suggests$.next(suggests);
            this._cdr.detectChanges();
          });
      });
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  onFocusOut() {
    timer(400).subscribe(() => this.suggests$.next([]));
  }

  onFocus() {
    console.log("focus");
  }

  selectSuggest(suggest: YaSuggestGeoObj) {
    console.log({ suggest });
    this.addressControll.patchValue(suggest.value || '', { emitEvent: false });
    this.suggests$.next([]);
    this._cdr.detectChanges();
    this._yaMapService.getGeoObject$(suggest.value || '')
      .subscribe(geo => {
        const address = this._address || new Address();
        address.applyYaGeo(geo);
        this._onChange(address);
        console.log(geo.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components);
        console.log(geo);
      });
  }

}
