import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortWithComparator'
})
export class SortWithComparatorPipe implements PipeTransform {

  transform<T>(value: T[], copmarator: (v0: T, v1: T) => number, ask = false): T[] {
    return value.sort((v0, v1) => copmarator(v0, v1) * (ask ? -1 : 1));
  }

}
