import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'inl-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent implements ControlValueAccessor, OnDestroy, OnInit {

  @Input() formControl: FormControl<any> = new FormControl<string>('');
  @Input() type: string = ''
  @Input() inputType: 'input' | 'text-area' = 'input';
  @Input() placeholder: string = '';
  @Input() label: string | null = null;
  @Input() maxRows = 4;
  @Input() minRows = 1;
  @Input() indicateError = true;

  constructor(
  ) { }

  ngOnDestroy(): void {
  }
  writeValue(v: string): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  ngOnInit() {
  }

}
