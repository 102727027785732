import { ISerializable } from './../models/core/i-serializable';
import { Injectable } from '@angular/core';
import { isPrimitive } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  private _storagePrefix = 'INLISTO_BACK_OFFICE';

  setItem(key: string, value: any) {
    const stringValue = this._isSerializable(value) ? value.toJSON() : JSON.stringify(value);
    localStorage.setItem(this.getStorageKey(key), stringValue);
  }

  getItem(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(this.getStorageKey(key)) || '');
    } catch (e) {
      return null;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(`${this._storagePrefix}.${key}`);
  }

  private _isSerializable(value: any): value is ISerializable {
    if (isPrimitive(value)) {
      return false;
    }
    return 'toJSON' in value;
  }

  getStorageKey(key: string): string {
    return `${this._storagePrefix}.${key}`;
  }

}


